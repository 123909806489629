import React, { useEffect, useRef, useState } from 'react';
import './fonts.css';
import './style.css';
import WOW                                    from 'wow.js';

import 'animate.css/animate.min.css';

function FourthSection() {
  const sectionRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  // Initialize WOW.js
  useEffect(() => {
    const wow = new WOW();
    wow.init();
  }, []);

  // Intersection Observer to trigger animations
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          setIsVisible(true); // Trigger animations when in view
        } else {
          setIsVisible(false); // Reset when out of view
        }
      },
      { threshold: 0.5 } // Trigger when 50% of the section is visible
    );

    const currentSection = sectionRef.current;
    if (currentSection) {
      observer.observe(currentSection);
    }

    return () => {
      if (currentSection) {
        observer.unobserve(currentSection);
      }
    };
  }, []);

  return (
    <section ref={sectionRef} className="container pt-5 my-5">
      <div className="row justify-content-center">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
          {/* Animation classes based on visibility */}
          <h1 className={`poppins-bold grey-text text-center wow animate__animated ${isVisible ? 'animate__bounceIn' : ''}`}>
            Catalysts of Change, Architects of Yesterday
          </h1>
          <p className={`poppins-regular text text-center wow animate__animated ${isVisible ? 'animate__fadeInUp' : ''}`} data-wow-delay="0.3s">
            Empowering Progress, Shaping Sustainable Futures: Our Group of Companies is committed to pioneering innovation, driving sustainability, and fostering positive transformation across industries, building a brighter, greener future for generations to come.
          </p>
        </div>
      </div>
    </section>
  );
}

export default FourthSection;
