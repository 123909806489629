import React                          from 'react';
// import axios                          from 'axios';
import Header                         from './components/header';
import Footer                         from './components/footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Video                          from './sections/video';
import FirstSection                   from './sections/firstsection';
import SecondSection                  from './sections/secondsection';
import ThirdSection                   from './sections/thirdsection';
import FourthSection                  from './sections/fourthsection';
import FifthSection                   from './sections/fifthsection';
import SixthSection                   from './sections/sixthsection';
import Blogs                          from './sections/blogs';
import OrgChart                       from './sections/orgchart';


function App() {
  // const [message, setMessage] = useState('');

  // useEffect(() => {
  //   axios.get('http://localhost:5000/')
  //     .then(response => {
  //       setMessage(response.data);
  //     })
  //     .catch(error => {
  //       console.log(error);
  //     });
  // }, []);

  return (
    <div className="App">
      <Header />
      <Video />
      <FirstSection />
      <SecondSection />
      <ThirdSection />
      <FourthSection />
      <FifthSection />
      <SixthSection />
      <OrgChart />
      <Blogs />
      <Footer />
    </div>
  );
}

export default App;
