import React, { useEffect, useState, useCallback, useRef } from 'react';
import './style.css';
import tgps from '../assets/logo/tgps.png';

function Header() {
  const [isVisible, setIsVisible] = useState(true);
  const [isHovered, setIsHovered] = useState(false);
  const lastScrollY = useRef(window.scrollY);
  const timeoutRef = useRef(null);

  const handleScroll = useCallback(() => {
    // Reset visibility timeout
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    // Show header on scroll up
    if (window.scrollY < lastScrollY.current) {
      setIsVisible(true);
    } else {
      setIsVisible(false); // Hide on scroll down
    }

    // Update last scroll position
    lastScrollY.current = window.scrollY;

    // Set timeout to hide after 5 seconds if not hovered
    if (!isHovered) {
      timeoutRef.current = setTimeout(() => {
        setIsVisible(false);
      }, 2000);
    }
  }, [isHovered]);

  const handleMouseEnter = () => {
    setIsHovered(true);
    // Clear timeout when hovering over the header
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    // Set timeout to hide after 2 seconds when mouse leaves
    timeoutRef.current = setTimeout(() => {
      setIsVisible(false);
    }, 200); // Changed to 0.2 seconds
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      clearTimeout(timeoutRef.current); // Cleanup the timeout on unmount
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return (
    <header
      className={`header ${isVisible ? 'visible' : 'hidden'}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <nav className="navbar navbar-expand-lg bg-body-light">
        <div className="container">
          <a className="navbar-brand d-flex justify-content-center align-items-center" href="/">
            <img src={tgps} alt="Logo" className="img-fluid" width="90%" />
          </a>
          <button className="navbar-toggler ms-auto" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Group
                </a>
                {/* <ul className="dropdown-menu bg-body-success">
                  <li><a className="dropdown-item" href="/">About Us</a></li>
                  <li><a className="dropdown-item" href="/">The TGPS way</a></li>
                  <li><a className="dropdown-item" href="/">Awards</a></li>
                  <li><a className="dropdown-item" href="/">Life @ TGPS</a></li>
                </ul> */}
              </li>
              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Businesses
                </a>
                <ul className="dropdown-menu bg-body-success">
                  <li><a className="dropdown-item" href="https://greencarpet.world/" target="_blank" rel="noopener noreferrer">Green Carpet</a></li>
                  <li><a className="dropdown-item" href="https://frequencychain.org/" target="_blank" rel="noopener noreferrer">Frequency Chain</a></li>
                  <li><a className="dropdown-item" href="https://frecharge.net/" target="_blank" rel="noopener noreferrer">FreC Charge</a></li>
                  <li><a className="dropdown-item" href="https://frequencyev.com/" target="_blank" rel="noopener noreferrer">Frequency Vehicle</a></li>
                  <li><a className="dropdown-item" href="https://tetssol.com/" target="_blank" rel="noopener noreferrer">Tetssol</a></li>
                  <li><a className="dropdown-item" href="https://tgpsinfotech.com/" target="_blank" rel="noopener noreferrer">TGPS Infotech</a></li>

                </ul>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/">Careers</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/">Media</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/">Connect</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;
