import React        from "react";

import './style.css';
import Stack        from 'react-bootstrap/Stack';

import frecx        from '../assets/logo/frecx.png';
import greencarpet  from '../assets/logo/greencarpet.png';

import fc           from '../assets/logo/fc.png';

import fv           from '../assets/logo/fv.png';
import zigoo        from '../assets/logo/zigoo.png';

import tgpsinfotech from '../assets/logo/tgpsinfotech.png';
import tets from '../assets/logo/tets.png';

function FifthSection() {
    const mediaItems = [
        {
            imageSrc: tets,
            title: "Transcendent",
            description: "Harnessing the sun's power, Tetssol provides turnkey solar energy solutions for commercial, residential, and industrial sectors. From design to installation, Tetssol drives clean energy access for a brighter, greener tomorrow.",
            hoverClass: "tets"
        },
        {
            imageSrc: fv,
            title: "Frequency Vehicle",
            description: "Experience the drive of the future with Frequency Vehicle's EV and charging solutions. Redefining transportation with electric vehicles, battery technology, and sustainable mobility options that bring energy-efficient journeys to life.",
            hoverClass: "fv"
        },
        {
            imageSrc: fc,
            title: "FreC Charge",
            description: "Power up sustainably with FreC Charge! Offering seamless EV charging solutions, FreC Charge blends speed, convenience, and eco-friendly technology to keep you on the move. Transform your drive with reliable, accessible, and green energy at every stop.",
            hoverClass: "fc"
        },
        {
            imageSrc: zigoo,
            title: "ZiGoo",
            description: "Your global marketplace, ZiGoo offers a diverse array of products to meet every need. Seamlessly bridging lifestyle and technology, ZiGoo creates a dynamic shopping experience where quality and convenience align.",
            hoverClass: "zigoo"
        },
        {
            imageSrc: tgpsinfotech,
            title: "TGPS Infotech",
            description: "Where tech innovation meets expertise. TGPS Infotech offers advanced IT solutions, guiding businesses through project development, consulting, and Blockchain-as-a-Service. Discover tailored digital transformations that redefine operational efficiency and innovation.",
            hoverClass: "tgps-infotech"
        },
        {
            imageSrc: frecx,
            title: "Frequency Chain X",
            description: "A next-gen blockchain exclusively tailored for renewable energy solutions. Frequency Chain X combines cutting-edge technology with sustainability, enabling decentralized apps and NFT-backed developments to power a cleaner, greener world.",
            hoverClass: "frequency-chain"
        },
        {
            imageSrc: greencarpet,
            title: "Green Carpet",
            description: "Empowering a sustainable future, Green Carpet Foundation stands for environmental change with a mission of ‘Planet, People, Prosperity.’ From carbon control to green conversion initiatives, the foundation is a catalyst for impactful environmental and social transformation.",
            hoverClass: "green-carpet"
        }
    ];

    return (
        <section className="container">
            <ul className="list-unstyled">
                {mediaItems.map((item, index) => (
                    <li key={index} className={item.hoverClass}>
                        <Stack direction="horizontal" gap={1} className="mt-4 container-fluid align-items-center flex-column flex-md-row">
                            <img
                                className="mx-5 bg-white img-fluid logo p-5"
                                src={item.imageSrc}
                                alt="Media object"
                            />
                            <div className="p-2">
                                <h1 className="poppins-bold text-center mt-3">{item.title}</h1>
                                <p className="poppins-regular text-center">
                                    {item.description}
                                </p>
                            </div>
                        </Stack>
                    </li>
                ))}
            </ul>
        </section>
    );
}

export default FifthSection;
