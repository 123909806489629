import React, { useEffect, useRef, useState } from 'react';
import WOW                                    from 'wow.js';


import 'animate.css/animate.min.css';
import './fonts.css';
import s6                                     from '../assets/section/s6.png';

import group                                  from '../assets/section/group.png';

function SixthSection() {
    const sectionRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);

    // Initialize WOW.js
    useEffect(() => {
        const wow = new WOW();
        wow.init();
    }, []);

    // Intersection Observer to trigger animations
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                const entry = entries[0];
                if (entry.isIntersecting) {
                    setIsVisible(true); // Trigger animations when in view
                } else {
                    setIsVisible(false); // Reset when out of view
                }
            },
            { threshold: 0.2 } // Trigger when 50% of the section is visible
        );

        const currentSection = sectionRef.current;
        if (currentSection) {
            observer.observe(currentSection);
        }

        return () => {
            if (currentSection) {
                observer.unobserve(currentSection);
            }
        };
    }, []);

    return (
        <section className="container my-2" ref={sectionRef}>
            {/* <div className="row justify-content-center p-5 bg-game-changers my-5">
                <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10 my-1">
                    <h1 className={`poppins-bold grey-text text-center mt-3 wow animate__animated ${isVisible ? 'animate__bounceIn' : ''}`}>
                        Game-changers
                    </h1>
                    <p className={`poppins-regular text text-center wow animate__animated ${isVisible ? 'animate__fadeInUp' : ''}`}>
                        A Legacy of Innovation and Sustainability Shaped Over 8 Years.
                        Discover more of our dynamic journey and the core values that define TGPS.
                    </p>
                </div>
            </div> */}
            <div className="container row justify-content-center my-5">
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 my-1 order-2 order-md-1 d-flex align-items-center flex-column justify-content-center">
                    <h3 className={`poppins-bold grey-text text-justify mt-3 wow animate__animated ${isVisible ? 'animate__bounceIn' : ''}`}>
                        What Defines Us and Ignites Our Mission?
                    </h3>
                    <p className={`poppins-regular text text-justify wow animate__animated ${isVisible ? 'animate__fadeInUp' : ''}`}>
                        Explore the transformative journey of TGPS as we’ve evolved into a global leader, championing
                        innovation and sustainability in energy solutions. Our unwavering dedication to excellence and our
                        core values continue to power progress, creating a lasting impact across.
                    </p>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 my-1 d-flex align-items-center justify-content-center order-1 order-md-2">
                    <img src={s6} alt="block" className="img-fluid" width="80%" />
                </div>
            </div>
            <div className="container row justify-content-center my-1">
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 my-1 d-flex align-items-center justify-content-center">
                    <img src={group} alt="group" className="img-fluid" width="100%" />
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 my-1 d-flex align-items-center flex-column justify-content-center">
                    <h3 className={`poppins-bold grey-text text-justify mt-3 wow animate__animated ${isVisible ? 'animate__bounceIn' : ''}`}>
                        A Culture that Inspires, Connects & Celebrates
                    </h3>
                    <p className={`poppins-regular text text-justify wow animate__animated ${isVisible ? 'animate__fadeInUp' : ''}`}>
                        At TGPS, we engage with our teams, communities, and the world through meaningful events and achievements.
                    </p>
                    <p className={`poppins-regular text text-justify wow animate__animated ${isVisible ? 'animate__fadeInUp' : ''}`}>
                        We honor excellence, celebrate milestones, and foster connections that makes a lasting impact.
                    </p>
                </div>
            </div>
        </section>
    );
}

export default SixthSection;
