import React, { useEffect, useRef, useState } from 'react';
import { Container, Row, Col }                from 'react-bootstrap';
import WOW                                    from 'wow.js';

import Transcent                              from '../assets/globalpartners/Transcent.webp';
import FlagInd                                from '../assets/globalpartners/flag_ind.webp';
import Binshehab                              from '../assets/globalpartners/binshehab.webp';
import FlagKuwait                             from '../assets/globalpartners/flag_kuwaut.webp';
import SaviLanka                              from '../assets/globalpartners/savilanka.webp';
import FlagSriLanka                           from '../assets/globalpartners/flag_sri.webp';
import Minerva                                from '../assets/globalpartners/minerva.webp';
import USFlag                                 from '../assets/globalpartners/us_flag.webp';

import 'animate.css/animate.min.css';

function ThirdSection() {
  const sectionRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  // Initialize WOW.js for other animations
  useEffect(() => {
    new WOW().init();
  }, []);

  // IntersectionObserver to trigger animations on scroll
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          setIsVisible(true); // Trigger animations when in view
        } else {
          setIsVisible(false); // Reset when out of view
        }
      },
      { threshold: 0.5 } // Trigger when 50% of the section is visible
    );

    const currentSection = sectionRef.current;
    if (currentSection) {
      observer.observe(currentSection);
    }

    return () => {
      if (currentSection) {
        observer.unobserve(currentSection);
      }
    };
  }, []);

  // Animation classes based on visibility
  const headingAnimationClass = isVisible ? 'animate__bounceIn' : '';
  const imageAnimationClass = isVisible ? 'animate__zoomIn' : '';

  return (
    <section ref={sectionRef} className="process my">
      <Container>
        <Row className="no-gutters">
          {/* Bounce-in effect for the heading */}
          <Col xs={12} className={`wow animate__animated ${headingAnimationClass}`}>
            <h1 className="poppins-bold grey-text text-center">Our Global Partners</h1>
            <p className="poppins-regular text text-center">
              Presence that Shapes the Future. The TGPS Group stands tall, driving innovation and impact worldwide.
            </p>
          </Col>

          {/* Zoom-in effect for the images */}
          <Col md={3} className={`wow animate__animated ${imageAnimationClass} mt-4 d-flex flex-column align-items-center`}>
            <a href="/" onClick={(e) => { e.preventDefault(); window.open('https://tetssol.com/'); }}>
              <img src={Transcent} style={{ height: '45px' }} alt="tetsol" />
            </a>
            <figcaption className="d-flex flex-column align-items-center">
              <img className="mt-4" width="48%" src={FlagInd} alt="Indian flag" />
            </figcaption>
          </Col>

          <Col md={3} className={`wow animate__animated ${imageAnimationClass} my-2 d-flex flex-column align-items-center`}>
            <a href="/" onClick={(e) => { e.preventDefault(); window.open('https://www.binshehab.com.kw'); }}>
              <img src={Binshehab} style={{ height: '60px' }} alt="binshehab" />
            </a>
            <figcaption className="d-flex flex-column align-items-center">
              <img className="mt-3" width="55%" src={FlagKuwait} alt="Kuwait flag" />
            </figcaption>
          </Col>

          <Col md={3} className={`wow animate__animated ${imageAnimationClass} my-2 d-flex flex-column align-items-center`}>
            <a href="/" onClick={(e) => { e.preventDefault(); window.open('https://savilanka.com/'); }}>
              <img src={SaviLanka} style={{ height: '60px' }} alt="savilanka" />
            </a>
            <figcaption className="d-flex flex-column align-items-center">
              <img className="mt-4" width="50%" src={FlagSriLanka} alt="Sri Lanka flag" />
            </figcaption>
          </Col>

          <Col md={3} className={`wow animate__animated ${imageAnimationClass} my-2 d-flex flex-column align-items-center`}>
            <a href="/" onClick={(e) => { e.preventDefault(); window.open('#'); }}>
              <img src={Minerva} style={{ height: '60px' }} alt="minerva" />
            </a>
            <figcaption className="d-flex flex-column align-items-center">
              <img className="mt-4" width="50%" src={USFlag} alt="US flag" />
            </figcaption>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default ThirdSection;
