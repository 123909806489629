import React                                                                             from 'react';

import './fonts.css';
import { Container, Row, Col }                                                           from 'react-bootstrap';

import tgps                                                                              from '../assets/logo/tgps.png';

import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedin, FaMedium }                     from 'react-icons/fa';





function Footer() {
  return (
    <footer className="text-center text-lg-start text-muted bg-light">
      <section className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
        <div className="me-5 d-none d-lg-block">
          <span className='poppins-bold grey-text'>Get connected with us on social networks:</span>
        </div>

        <div>
          <a 
            href="https://www.facebook.com/thaigreenpowersolutions" 
            className="me-4 link-hover"
            target="_blank" 
            rel="noopener noreferrer"
          >
            <FaFacebookF />
          </a>
          <a 
            href="https://x.com/thai_solution" 
            className="me-4 link-hover"
            target="_blank" 
            rel="noopener noreferrer"
          >
            <FaTwitter />
          </a>
          <a 
            href="https://www.instagram.com/tgps_global/" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="me-4 link-hover"
          >
            <FaInstagram />
          </a>

          <a 
            href="https://www.linkedin.com/company/89826499/admin/dashboard/" 
            target="_blank" 
            rel="noopener noreferrer"
            className="me-4 link-hover"
          >
            <FaLinkedin />
          </a>
          <a 
            href="https://medium.com/@admin_33552" 
            target="_blank" 
            rel="noopener noreferrer"
            className="me-4 link-hover"
          >
            <FaMedium />
          </a>
        </div>
      </section>

      <section>
        <Container className="text-center text-md-start mt-5">
          <Row className="mt-3">
            <Col md="3" lg="4" xl="3" className="mx-auto mb-4 d-flex align-items-center justify-content-center">
              <img src={tgps} alt="block" className="img-fluid" width="80%" />
            </Col>

            <Col md="2" lg="2" xl="2" className="mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4 poppins-semibold grey-text">Group</h6>
              <p>
                <a href="/" className="link-hover link-opacity-25-hover poppins-semibold">About Us</a>
              </p>
              <p>
                <a href="/" className="link-hover link-opacity-25-hover poppins-semibold">The TGPS Way</a>
              </p>
              <p>
                <a href="/" className="link-hover link-opacity-25-hover poppins-semibold">Awards</a>
              </p>
              <p>
                <a href="/" className="link-hover link-opacity-25-hover poppins-semibold">Life @ TGPS</a>
              </p>
            </Col>

            <Col md="3" lg="2" xl="2" className="mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4 poppins-semibold grey-text">Businesses</h6>
              
              <p>
                <a href="https://frequencychain.org/" className="link-hover link-opacity-25-hover poppins-semibold" target="_blank" rel="noopener noreferrer">Frequency Chain</a>
              </p>
              <p>
                <a href="https://frecharge.net/" className="link-hover link-opacity-25-hover poppins-semibold" target="_blank" rel="noopener noreferrer">FreC Charge</a>
              </p>
              <p>
                <a href="https://frequencyev.com/" className="link-hover link-opacity-25-hover poppins-semibold" target="_blank" rel="noopener noreferrer">Frequency Vehicle</a>
              </p>
              <p>
                <a href="https://tetssol.com/" className="link-hover link-opacity-25-hover poppins-semibold" target="_blank" rel="noopener noreferrer">Tetssol</a>
              </p>
              <p>
                <a href="https://tgpsinfotech.com/" className="link-hover link-opacity-25-hover poppins-semibold" target="_blank" rel="noopener noreferrer">TGPS Infotech</a>
              </p>
              <p>
                <a href="https://greencarpet.world/" className="link-hover link-opacity-25-hover poppins-semibold" target="_blank" rel="noopener noreferrer">Green Carpet</a>
              </p>

            </Col>

            <Col md="4" lg="3" xl="3" className="mx-auto mb-md-0 mb-4">
              <h6 className="text-uppercase fw-bold mb-4">Contact</h6>
              <p>
                <span className="me-2">&#127968;</span>  EmQuartier, Level 30, Sukhumvit, Bangkok, Thailand.
              </p>
              <p>
                <span className="me-3">&#9993;</span> info@tgpsglobal.com
              </p>
              {/* <p>
                <span className="me-3">&#128222;</span> + 01 234 567 88
              </p>
              <p>
                <span className="me-3">&#9990;</span> + 01 234 567 89
              </p> */}
            </Col>
          </Row>
        </Container>
      </section>

      <div className="text-center p-4" style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
        © 2024 Copyright:
        <a className="link-hover fw-bold mx-1 link-opacity-25-hover" href="https://tgpsglobal.com/">
          TGPS
        </a>
        All rights Reserved
      </div>
    </footer>
  );
}

export default Footer;
