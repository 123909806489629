import React, { useState } from "react";
import './fonts.css';
import './style.css';
import blog1               from '../assets/blog/1.png';
import blog2               from '../assets/blog/2.png';
import blog3               from '../assets/blog/3.png';

function Blogs() {
    const [email, setEmail] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [message, setMessage] = useState("");
    const [emailError, setEmailError] = useState("");

    // Simple email validation function
    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setEmailError("");
        setMessage("");

        if (!validateEmail(email)) {
            setEmailError("Please enter a valid email address.");
            return;
        }

        setIsSubmitting(true);
        
        try {
            await new Promise((resolve) => setTimeout(resolve, 2000));

            setMessage("Subscription successful! Check your inbox.");
            setEmail("");
        } catch (error) {
            setMessage("Subscription failed. Please try again.");
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <section className="container">
            <h1 className="display-1 poppins-black text-center" style={{ fontSize: '20vw', color: '#E8E8E8' }}>
                BLOGS
            </h1>
            <div className="row justify-content-center g-0 mb-5 blog-images">
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 d-flex justify-content-center align-items-center">
                    <img src={blog1} alt="block" className="img-fluid" />
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 d-flex justify-content-center align-items-center">
                    <img src={blog2} alt="block" className="img-fluid" />
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 d-flex justify-content-center align-items-center">
                    <img src={blog3} alt="block" className="img-fluid" />
                </div>
            </div>
            <div className="container">
                <h1 className="display-4 poppins-bold text-center grey-text">
                    NEWSLETTER
                </h1>
                <h4 className="poppins-medium mt-5 text-center">Subscribe and see what’s new</h4>
                <div className="card border-0 mb-5">
                    <form onSubmit={handleSubmit} className="d-flex justify-content-center align-items-center">
                        <div className="input-group input-group-lg mb-3 mt-2" style={{ maxWidth: '500px' }}>
                        <input
                            type="email"
                            className="form-control form-control-lg single-line-input" 
                            placeholder="Enter your e-mail address"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            aria-label="Recipient's email"
                            aria-describedby="button-addon2"
                            disabled={isSubmitting}
                        />

                            <button className="btn btn-outline-indigo" type="submit" id="button-addon2" disabled={isSubmitting}>
                                <b>{isSubmitting ? "Submitting..." : "Subscribe"}</b>
                            </button>
                        </div>
                    </form>

                    {emailError && <div className="invalid-feedback d-block">{emailError}</div>}
                    {message && <p className={`mt-3 ${message.includes("failed") ? 'text-danger' : 'text-success'}`}>{message}</p>}
                </div>
            </div>
        </section>
    );
}

export default Blogs;
