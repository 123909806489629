import React from "react";

function OrgChart() {
  return (
    <section className="container">
        <h1 className="display-1 poppins-black text-center" style={{ fontSize: '5vw', color: '#E8E8E8' }}>
                ORGANIZATION
            </h1>
      <iframe
        src="https://theorg.com/org/thai-green-power-soultion-co-ltd/embed"
        width="100%"
        height="550px"
        frameBorder="0"
        style={{ border: "1px solid #eaeaeb", borderRadius: "4px" }} // Corrected React-style inline CSS
        title="Org Chart"
      ></iframe>
    </section>
  );
}

export default OrgChart;
