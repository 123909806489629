import React from 'react';
import './video.css';
import video from '../assets/video/home.webm';
import tgps  from '../assets/logo/tgps.png';


function Video() {
  return (
    <section>
      <div className='container d-block d-md-none'>
        <div className='d-flex justify-content-center align-items-center'>
          <img src={tgps} alt="block" className="img-fluid" width="40%" />
        </div>
      </div>
      <div className="video-banner">
      <video className="video" autoPlay muted loop playsInline>
        <source src={video} type="video/webm" />
      </video>
      {/* <div className="video-overlay">
        <h1 className="video-title">Welcome to Our Website</h1>
      </div> */}
    </div>
    </section>

  );
};

export default Video;
